import styled from 'styled-components';


import Image2 from '../../assets/Intern Images/2.png';
import Image3 from '../../assets/Intern Images/3.png';
import Image4 from '../../assets/Intern Images/4.png';
import Image5 from '../../assets/Intern Images/5.png';
import Image6 from '../../assets/Intern Images/6.png';
import Image7 from '../../assets/Intern Images/7.png';
import Image8 from '../../assets/Intern Images/8.png';
import Image9 from '../../assets/Intern Images/9.png';
import Image10 from '../../assets/Intern Images/10.png';
import Image11 from '../../assets/Intern Images/11.png';
import Image12 from '../../assets/Intern Images/12.png';
import Image13 from '../../assets/Intern Images/13.png';


const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
`;

const Container = styled.div`
  width: 90vw;
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: 'Futura Std Heavy';
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ImgContainer = styled.img`
  // flex-grow: 1;
  // object-fit: cover;
  margin: 2px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.005);
  }
`;

const Content = [

  { type: 'img', src: Image2, alt: 'Intern Image' },
  { type: 'img', src: Image3, alt: 'Intern Image' },
  { type: 'img', src: Image4, alt: 'Intern Image' },
  { type: 'img', src: Image5, alt: 'Intern Image' },
  { type: 'img', src: Image6, alt: 'Intern Image' },
  { type: 'img', src: Image7, alt: 'Intern Image' },
  { type: 'img', src: Image8, alt: 'Intern Image' },
  { type: 'img', src: Image9, alt: 'Intern Image' },
  { type: 'img', src: Image10, alt: 'Intern Image' },
  { type: 'img', src: Image11, alt: 'Intern Image' },
  { type: 'img', src: Image12, alt: 'Intern Image' },
  { type: 'img', src: Image13, alt: 'Intern Image' },

];

const EventsDisplay = () => {
  return (
    <MainContainer>
      <Container>
        <Title>Gallery</Title>
        <ContentContainer>
          {Content.map((item, idx) => {
            return <ImgContainer src={item.src} alt={item.alt} className='gallery-img'/>;
          })}
        </ContentContainer>
      </Container>
    </MainContainer>
  );
};

export default EventsDisplay;
