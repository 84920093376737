import styled from "styled-components";

import BoardCard from "./BoardCard/BoardCard";

import President from "../../assets/Team/1.jpeg";
import FinanceAdvisor from "../../assets/Team/2.jpeg";
import NS from "../../assets/Team/3.png";
import IRHR from "../../assets/Team/4.png";
import Admin from "../../assets/Team/5.png";
import Outgoing from "../../assets/Team/6.png";
import Incoming from "../../assets/Team/7.png";
import CMA from "../../assets/Team/8.png";
import Coporate from "../../assets/Team/9.png";
import Finance from "../../assets/Team/10.png";
import PR from "../../assets/Team/11.png";
import ITDA from "../../assets/Team/12.png";

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0px;
`;

const Container = styled.div`
  width: 90vw;
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 2rem;
  column-gap: 2rem;
`;

const Title = styled.h1`
  color: #0b3d59;
  font-family: "Futura Std Heavy";
  font-size: 2.25rem;
  margin: 0px 0px 50px 0px;
  text-align: center;
`;
const BoardMembers = () => {
  return (
    <MainContainer>
      <Container>
        <Title>Board Members</Title>
        <Cards>
          <BoardCard
            image={President}
            name="DR. KARUNAKAR A KOTEGAR"
            title="President"
            mail="karunakar.ak@manipal.edu"
            linkedin="https://www.linkedin.com/in/prof-dr-karunakar-a-kotegar-31381119/"
          />
          <BoardCard
            image={FinanceAdvisor}
            name="DR.R ELIJAH BLESSING"
            title="Financial Advisor"
            mail="registrar@karunya.edu"
            linkedin="https://www.linkedin.com/in/elijah-blessing-rajsingh-aa260694/"
          />
          <BoardCard
            image={NS}
            name="PALLAVI KAILAS"
            title="National Secretary"
            mail="nationalsecretary@iaeste.in"
            linkedin="https://www.linkedin.com/in/pallavi-kailas-65b561245/"
          />
          <BoardCard
            image={IRHR}
            name="VIBHAWARI DHOBLE"
            title="Head, Internal Relations & HR"
            mail="internal.relations@iaeste.in"
            linkedin="https://www.linkedin.com/in/vibhawari-dhoble"
          />
          <BoardCard
            image={Admin}
            name="FAIZAN ALI SAYED"
            title="Head, Administration"
            mail="head.administration@iaeste.in"
            linkedin="https://www.linkedin.com/in/faizan-ali-sayed/"
          />
          <BoardCard
            image={Outgoing}
            name="GURBANI KAUR"
            title="Head, Outgoing Exchange"
            mail="outgoing.exchange@iaeste.in"
            linkedin="https://www.linkedin.com/in/gurbani-kaur-saluja/"
          />
          <BoardCard
            image={Incoming}
            name="PHILIP ANNADURAI"
            title="Head, Incoming Exchange"
            mail="incoming.exchange@iaeste.in"
            linkedin="https://www.linkedin.com/in/philip-annadurai-3548a1249?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
          />
          <BoardCard
            image={CMA}
            name="SWARATMIKA TIWARI"
            title="Head, Consular and Member Affairs"
            mail="external.affairs@iaeste.in"
            linkedin="https://www.linkedin.com/in/swaratmika-tiwari-65522b1b4/"
          />
          <BoardCard
            image={Coporate}
            name="RAICHEL THOMAS"
            title="Head, Corporate and Alumni Relations"
            mail="external.affairs@iaeste.in"
            linkedin="https://www.linkedin.com/in/raichelthomas1604/"
          />
          <BoardCard
            image={Finance}
            name="SAANVI KONDE"
            title="Head, Finance"
            mail="head.finance@iaeste.in"
            linkedin="https://www.linkedin.com/in/saanvi-konde-776769282/"
          />
          <BoardCard
            image={PR}
            name="JERLIN SAM RN"
            title="Head, Public Relations"
            mail="external.affairs@iaeste.in"
            linkedin="https://www.linkedin.com/in/jerlin-sam/"
          />
          <BoardCard
            image={ITDA}
            name="GODFRAY BUEX"
            title="Head, Information Technology and Data Analytics"
            mail="itda.iaeste.in@gmail.com"
            linkedin="https://www.linkedin.com/in/godfray-buex-4b1a61249?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
          />
        </Cards>
      </Container>
    </MainContainer>
  );
};

export default BoardMembers;
